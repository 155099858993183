import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { handlePbcLogin, isBrowser } from "../services/auth"
import { Auth } from "aws-amplify"
import { CircularProgress, Backdrop } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { getCookie } from "../utils/cookieHelper"
import cloverPricingPDF from "../images/clover-pricing-2022.pdf" //TODO placeholder for FAQ PDF

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 9,
    color: "#000000",
  },
}))

const emailRedirect = () => {
  let encodedTimestampKV = getCookie("encodedTimestamp")
  let emailOrderRedirectKV = getCookie("emailOrderRedirectURL")
  let emailFAQRedirectKV = getCookie("emailFAQRedirectURL")
  if (encodedTimestampKV && emailOrderRedirectKV[1] === "order-card") {
    return `/app/orders/order-summary/#` + encodedTimestampKV[1]
  } else if (emailFAQRedirectKV[1] === "faq") {
    return cloverPricingPDF
  } else {
    return `/app/dashboard`
  }
}

const IndexPage = ({ location }) => {
  if (isBrowser()) {
    var url = window.location.hash.lastIndexOf("/")
    var encodedTimestamp = window.location.hash.substring(url + 1)
    var emailOrderRedirectURL = window.location.href.match(/(order-card)/g)
    var emailFAQRedirectURL = window.location.href.match(/(faq)/g)

    document.cookie = "encodedTimestamp=" + encodedTimestamp
    document.cookie = "emailOrderRedirectURL=" + emailOrderRedirectURL
    document.cookie = "emailFAQRedirectURL=" + emailFAQRedirectURL

    if (window?.location?.href.indexOf("clover") > -1) {
      navigate("/clover" + window.location.search)
    }
    Auth.currentAuthenticatedUser()
      .then(user => {
        navigate(emailRedirect())
      })
      .catch(err => {
        // Try to login via Cognito custom auth
        handlePbcLogin()
          .then(user => {
            navigate(emailRedirect())
          })
          .catch(err2 => {
            if (process.env.NODE_ENV === "development" || process.env.GATSBY_SHOW_AMPLIFY_LOGIN) {
              navigate(emailRedirect())
            } else {
              navigate("/logged-out")
            }
          })
      })
  }

  const classes = useStyles()
  return (
    <Layout location={location}>
      <Seo title="Welcome" />
      <Backdrop open={true} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </Layout>
  )
}

export default IndexPage
