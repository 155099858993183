export const getCookie = cookie => {
  let cookieArr = document.cookie.split(";")

  for (let i = 0; i < cookieArr.length; i++) {
    let cookiePair = cookieArr[i].split("=")
    if (cookie === cookiePair[0].trim()) {
      let cookieName = decodeURIComponent(cookiePair[0])
      let cookieValue = decodeURIComponent(cookiePair[1])
      let returnCookie = [cookieName, cookieValue]
      return returnCookie
    }
  }
  return null
}

export const deleteCookie = toBeDeleted => {
  let cookie = getCookie(toBeDeleted)
  if (cookie !== null) {
    document.cookie = toBeDeleted + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
  }
}
